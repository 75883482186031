* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rethink Sans", sans-serif;
}

.wrapper {
  margin: 0 auto;
  width: 90%;
  max-width: 1500px;
}

#root,
html {
  width: 100%;
  background-color: #03101e;
}

#contacto {
  position: relative;
}

#portfolio {
  position: relative;
}

#inicio {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

/*HOME*/

.principal-home-container {
  position: relative;
  z-index: 9;

  .background-video {
    width: 100%;
  }

  h1 {
    position: absolute;
    color: white;
    z-index: 10000;
    top: 30%;
    font-size: 8vw;
    font-style: italic;
    letter-spacing: 5px;
  }
}

.video-responsive {
  width: 100%;
  display: none;
}

/*HEADER*/
.header-container {
  width: 100%;
}

.menuscroll .header-details {
  background: linear-gradient(to bottom, #000000, transparent);
}

.header-details {
  width: 100%;
  padding: 40px 0;
  position: fixed;
  z-index: 10000;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo-principal {
  width: 170px;
}

/*NAV BAR*/

.languague-section {
  margin-bottom: 30px;
  img {
    width: 20px;
    margin-right: 3px;
  }
  button {
    all: unset;
    display: flex;
    cursor: pointer;
  }
  a {
    font-weight: 500;
  }
}

.nav-close {
  all: unset;
  width: 50px;
  height: 40px;
  padding: 7px;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  span {
    &:before,
    &:after {
      width: 40px;
      height: 4px;
      border-radius: 50px;
      background-color: #fff;
      content: "";
      position: absolute;
      left: 12.5px;
      transform-origin: 50% 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.nav-container {
  /*   background: linear-gradient(
    to bottom left,
    #f124cd,
    #aad1f5,
    #432d7f,
    #86089f,
    #442e80
  ); */
  background-image: url(../src/Assets/header-gradient.jpeg);
  background-size: cover;
  height: 100%;
  padding: 60px 40px;
  z-index: 30000;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition: 0.3s all;

  a:hover {
    border-bottom: 2px solid white;
    transition: 0.5s all;
  }
}

ul {
  margin-bottom: 280px;
}

.nav-container-open {
  transform: translate3d(0, 0, 0);
}

li {
  list-style: none;
  margin: 40px 0;
}

.item-navegacion {
  text-decoration: none;
  color: white;
  font-size: 30px;
  font-weight: 600;
  transform: translate3d(400px, 0, 0);
  transition: 0.8s all;
  display: inline-block;
  transition-delay: 0.1s;
}

.item-navegacion-open {
  transform: translate3d(0, 0, 0);
}

p {
  color: rgba(252, 252, 252, 0.778);
  font-weight: 700;
}

.logo-redes {
  width: 22px;
  margin: 30px 4px;
}

/*HAMBURGER MENU*/

.hamburguer-menu {
  cursor: pointer;
  border: none;
  background-color: transparent;
  z-index: 4000000;
  height: 40px;
  top: 0;
}

.hamburguer-menu span {
  border: 2px solid white;
  border-radius: 3px;
  margin: 8px 0;
  display: block;
  width: 43px;
  transition: ease-out 0.3s;
}

.hamburguer-menu span:nth-child(1) {
  transform-origin: 50% 50%;
}

.hamburguer-menu.open span:nth-child(1) {
  transform: translateY(11px) rotate(45deg);
}

.hamburguer-menu.open span:nth-child(2) {
  opacity: 0;
  transform: translateX(-30px);
}

.hamburguer-menu span:nth-child(3) {
  transform-origin: 50% 50%;
}

.hamburguer-menu.open span:nth-child(3) {
  transform: translateY(-12px) rotate(-45deg);
}

/*ABOUT US  --- CONTACT*/

.services-container {
  margin-top: 40px;
  display: grid;
  grid-template-columns: minmax(0, 30fr) minmax(0, 30fr) minmax(0, 30fr);
  gap: 40px;

  h1,
  h2 {
    color: white;
  }

  h1 {
    font-size: 32px;
    padding: 16px 2px;
    text-align: center;
    font-family: "Rethink Sans", sans-serif;
  }

  .web-design,
  .online-store,
  .web-development {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 20px;
    margin-bottom: 20px;

    img {
      width: 24px;
      color: white;
      margin-right: 10px;
    }
  }

  .web-design {
    background: linear-gradient(
      to right,
      #fff,
      #fa74b1,
      #eb3367,
      #fa69aa,
      #ff77e1
    );
  }

  .online-store {
    background: linear-gradient(
      to right,
      #fecc53,
      #ffb53c,
      #ff8326,
      #ff6646,
      #febe74
    );
  }

  .web-development {
    background: linear-gradient(
      to right,
      #7bbdbb,
      #5eb6de,
      #76e9fb,
      #36aee2,
      #0864ad
    );
  }

  h2 {
    font-size: 18px;
    padding: 20px;
    min-height: 250px;
    border: 1px solid white;
    border-radius: 20px;
  }
}

.about-us-details,
.contact-container {
  padding: 60px 0;

  h1,
  h2,
  h3 {
    text-align: center;
  }

  h1,
  h3 {
    font-size: 24px;
    color: rgba(244, 244, 244, 0.365);
  }

  h2 {
    font-size: 48px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 50px;
    color: white;
  }

  h1 {
    font-family: "Kalnia", serif;
    margin-bottom: 40px;
  }
}

.contact-background {
  background-image: url(./Assets/pre-footer-final.png);
  background-size: cover;
  height: 400px;
}

.contact-container div {
  span {
    color: rgba(244, 244, 244, 0.365);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  p {
    font-size: 40px;
    text-align: center;
    color: white;
    border: 1px solid white;
    border-radius: 24px;
    padding: 10px 30px;
    font-weight: 500;
  }
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(244, 244, 244, 0.365);
    font-size: 22px;
    font-weight: 600;
  }

  img {
    width: 40px;
    margin-right: 20px;
  }
}

.line {
  margin-top: 60px;
  max-width: 100%;
  width: 800px;
  color: white;
  border-top: 1px solid white;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-redes {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*PORTFOLIO*/

.portfolio-details {
  text-align: center;
  padding: 60px;

  h1 {
    color: rgba(244, 244, 244, 0.365);
    font-size: 24px;
    margin-bottom: 30px;
    font-family: "Kalnia", serif;
    text-align: center;
  }

  h2 {
    color: white;
    font-size: 60px;
    font-style: italic;
    font-weight: 400;
  }
}

.websites-principal {
  margin: 16px 0;
}

.websites-container {
  display: flex;
  margin: 0;

  .portfolio-web {
    width: 100%;
    display: block;
    position: relative;
  }

  .portfolio-responsive {
    display: none;
  }

  a:hover {
    opacity: 0.3;
  }
}
.websites-container {
  display: flex;
  justify-content: flex-start;
}

.portfolio-web,
.portfolio-responsive {
  max-width: 100%;
  height: auto;
  opacity: 0;
  transform: translateY(100%) scale(1);
  transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out;
}

/* Aplicar la transformación y la opacidad solo cuando la clase 'animate' está presente */
.animate .portfolio-web.animated,
.animate .portfolio-responsive.animated {
  transform: translateY(0) scale(1);
  opacity: 1;
}

/* Estilos específicos para cada imagen con retraso en la aparición */
.animate .portfolio-web.animated[src$="rosee-image.png"],
.animate .portfolio-responsive.animated[src$="rosee-responsive.png"] {
  transform-origin: 100% 50%;
  animation: fadeInUp 0.8s ease-in-out forwards, fadeInDelay 1s ease-in-out; /* Agrega una animación específica para la imagen Rosee */
}

.animate .portfolio-web.animated[src$="any-natural-image.png"],
.animate .portfolio-responsive.animated[src$="anynatural-responsive.png"],
.animate .portfolio-web.animated[src$="servicepro-image.png"],
.animate .portfolio-responsive.animated[src$="servicepro-responsive.png"],
.animate .portfolio-web.animated[src$="serena-portfolio.png"],
.animate .portfolio-responsive.animated[src$="serena-responsive.png"] {
  transform-origin: 0 100%;
  animation: fadeInLeft 0.8s ease-in-out forwards, fadeInDelay 0.3s ease-in-out; /* Agrega una animación específica para las imágenes Any, ServicePro, y Serena */
}

/* @keyframes para fadeInDelay */
@keyframes fadeInDelay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0; /* Mantén la opacidad en 0 para que no afecte la animación de escala */
  }
}

/* @keyframes para fadeInUp */
@keyframes fadeInUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

/* @keyframes para fadeInLeft */
@keyframes fadeInLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/*FOOTER*/

.wolf-background {
  width: 100%;
}

.footer-container {
  background-color: white;
  padding: 5px 0;
}

.logo-athom {
  width: 160px;
}

.footer-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.footer-section {
  display: flex;
  align-items: center;
}

.footer-section-2,
.footer-section-3,
.footer-section-4 {
  p,
  a {
    color: black;
    font-size: 12px;
    font-weight: 500;
  }

  a {
    text-decoration: none;
  }
}

.footer-section-4 {
  display: flex;
  align-items: center;

  a {
    font-weight: 500;
    font-size: 16px;
    margin-left: 3px;
  }
}

.logo-redes-footer {
  width: 20px;
  margin: 0 5px;
}

.separator-sections {
  height: 70px;
  color: black;
  width: 1px;
  border: 1px solid black;
  margin: 0 40px;
}

/*RESPONSIVE*/

@media screen and (max-width: 960px) {
  .background-video {
    display: none;
  }

  .video-responsive {
    display: block;
  }

  .services-container {
    display: block;

    h1 {
      font-size: 22px;
    }
  }

  .web-development,
  .online-store {
    margin-top: 40px;
  }
  .footer-details {
    display: block;
    text-align: center;
  }

  .footer-section {
    justify-content: center;
  }

  .footer-section-4 {
    margin-top: 20px;
    display: block;
  }

  .hamburguer-menu {
    margin-left: 80px;
  }

  .logo-principal {
    width: 150px;
  }

  .principal-home-container h1 {
    top: 50%;
  }
}

@media screen and (max-width: 650px) {
  .footer-section-2,
  .footer-section-3 {
    margin-top: 10px;
  }

  .separator-sections {
    display: none;
  }

  .footer-section {
    display: block;
  }

  .websites-container {
    display: block;

    img {
      max-height: 600px;
    }
  }

  .web-details {
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 30px;
      border: 1px solid white;
    }

    .portfolio-responsive {
      display: block;
    }

    .portfolio-web {
      display: none;
    }
  }

  .portfolio-details {
    margin: 0 auto;

    h2 {
      font-size: 26px;
    }
    h1 {
      margin-top: 20px;
    }
  }

  .websites-principal {
    margin: 60px 0;
  }

  .line {
    margin-top: 20px;
  }

  .contact-container {
    padding: 20px 0;
  }

  .contact-container div {
    margin-bottom: 20px;

    h2 {
      margin-bottom: 4px;
    }

    p {
      border: none;
      font-size: 20px;
    }

    span,
    a {
      font-size: 16px;
    }
    img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 430px) {
  .item-navegacion {
    font-size: 22px;
  }
  .about-us-container,
  .contact-container {
    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }
  }

  .line {
    width: 200px;
  }

  .portfolio-details {
    margin: 0 auto;
  }

  .portfolio-details h2 {
    font-size: 32px;
  }

  .contact-background {
    display: none;
  }

  .principal-home-container img {
    height: 350px;
  }

  .contact-container div p {
    padding: 10px;
  }
}
